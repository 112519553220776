.satisfeitos {
    background-color: #dde0e2;
    background: url('../../assets//wp6.jpg') no-repeat;
    background-position: 0 -20rem;
    background-size: cover;
}

.satisfeitos h3 {
    font-size: 1.5rem;
    width: fit-content;
    display: flex;
    align-self: center;
}

.satisfeitos h1 {
    font-size: 3rem;
    width: fit-content;
    display: flex;
    align-self: center;
}

.satisfeitos-glass {
    background-color: rgba(7, 30, 94, 0.719);
    color: #f5f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-y: hidden;
}

.satisfeitos-glass .show {
    opacity: 1;
    transform: translateY(0);
    transition: ease-out .75s;
}

.satisfeitos-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    opacity: 0;
    transform: translateY(100%);
}

.satisfeitos-body div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    height: 7.5rem;
    padding: 1%;
    float: left;
}

.satisfeitos-body div .numero {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.satisfeitos-body div p {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.satisfeitos-body div svg {
    margin-right: .5rem;
}

@media screen and (max-width:700px) {
    .satisfeitos-body {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .satisfeitos-body div {
        width: 100%;
        margin-bottom: 2.5rem;
    }

    .satisfeitos {
        background-color: #dde0e2;
        background: url('../../assets//wp6.jpg') no-repeat;
        background-position: -10rem 0;
        background-size: cover;
    }

}
