.header-color {
    background-color: #132f53b7;
    background: url('../../assets/menuCarousel2.jpg') no-repeat;
    background-size: cover;
}

.header-glass {
    background-color: rgba(6, 16, 59, 0.719);
}

.profile-section {
    height: calc(100vh - (4rem + 7.5rem));
    background-color: #f5f4f4;
}