.servicos {
    color: #1c1c1c;
    background-color: #050c14e3;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.servicos h1 {
    font-size: 2.5rem;
    border-top: 1rem solid rgb(3, 167, 167);
    width: fit-content;
    display: flex;
    align-self: center;
}

.services {
    width: 100%;
    color: #1c1c1c;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.service-show {
    background-color: #f5f4f4;
    transition: ease 1.5s;
}

.services .service-info {
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    margin: 10px;
    border-radius: 2.5%;
    color: #1c1c1c;
    position: relative;
    float: inline-start;
    opacity: 0;
    transform: translateY(100%);
}

.services .show {
    opacity: 1;
    transform: translateY(0);
    transition: ease-out .75s;
}

.services .service-info:hover ~ .service-icon{
    box-shadow: 0 0 .5rem #1c1c1c;
    transition: ease .75s;
}

.services .service-info .service-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    width: 150px;
    height: 150px;
    font-size: 5rem;
    color: #1c1c1c;
    margin-bottom: 5%;
}

.services .service-info .service-icon:hover{
    background-color: #1c1c1c;
    color: #f5f4f4;
    transition: ease .75s;
}

.services .service-info .service-text {
    height: 170px;
    overflow: auto;
    text-align: justify;
}

.services .service-info p {
    margin-top: 5%;
}

.services .service-info button {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 3px solid #1c1c1c;


    font-weight: bold;
    font-size: 1rem;
    color: #1c1c1c;
}

.service-info .show {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 40%;
    height: 15%;
    border-radius: 50px;

    opacity: 0;
    position: absolute;
    bottom: 5%;
}

.service-info .show button:hover{
    background-color: #1c1c1c;
    color: #f5f4f4;
    transition: ease .75s;
}

.service-info:hover > .show {
    transition: ease 1s;
    opacity: 1;
}

@media screen and (max-width:700px) {

    .services {
        width: 100%;
        color: #1c1c1c;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

}