.header-color {
    background-color: #132f53b7;
    background: url('../../assets/menuCarousel2.jpg') no-repeat;
    background-size: cover;
}

.header-glass {
    background-color: rgba(6, 16, 59, 0.719);
}

.contrato-section {
    height: calc(100vh - (4rem));
    background-color: #f5f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contrato-section iframe {
    margin: 0;
    width: 100%;
    height: 100%;
}

