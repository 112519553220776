.quem-somos {
    color: #1c1c1c;
    background-color: #f5f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-x: hidden;
}

.quem-somos h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
} 

.quem-somos h1 {
    font-size: 2.75rem;
    width: fit-content;
    margin-bottom: 1.5rem;
    display: flex;
    align-self: center;
}

.quem-somos-text {
    opacity: 0;
    transform: translateX(100%);
}

.quem-somos-body .show {
    opacity: 1;
    transform: translateX(0);
    transition: ease-out 1.5s;
}

.quem-somos-body div {
    width: 50%;
    padding: 1%;
    float: left;
}

.quem-somos-body div img {
    width: 100%;
}

.quem-somos p {
    font-size: 1.25rem;
    margin-top: 1rem;
    text-indent: 2.5rem;
}

@media screen and (max-width:700px){

    .quem-somos {
        padding: 20px;
    }

    .quem-somos-body .image-div {
        display: none;
    }

    .quem-somos-body div {
        padding: 0;
        width: 100%;
    }

    .quem-somos-body div p {
        font-size: 1.15rem;
    }
}