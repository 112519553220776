#home {
    height: 100vh;
    width: 100%;
    background: url(../../assets/menuCarousel2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.home {
    height: 100vh;
    width: 100%;
    background: url(../../assets/menuCarousel2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    
}

.home-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(6, 16, 59, 0.719);
    overflow-y: hidden;
}

.hero {
    margin-top: 3.5rem;
    margin-left: 7.5rem;
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.hero h2 {
    font-size: 1.8rem;
}

.hero h1 {
    font-size: 5.2rem;
}

.hero button {
    border-radius: 3rem;
    cursor: pointer;
    background-color: #f5f4f400;
    border: .1rem solid #f5f4f4;
    font-size: 1.5rem;
    width: 30%;
    height: 20%;
    color: #f5f4f4;
}

.hero button:hover{
    transition: ease-in-out .5s;
    background-color: #f5f4f4;
    color: #1c1c1c;
    border-color: #1c1c1c;
}

.hero button a {
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width:700px) {
    .hero {
        padding: 0;
        margin-top: 0;
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        
    .hero h2 {
        font-size: 1.75rem;
    }

    .hero h1 {
        font-size: 2.5rem;
    }
        
    .hero button {
        display: none;
    }
}
