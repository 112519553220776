.footer {
    background-color: #191818;
    width: 100%;
    height: 2.5rem;
    display: flex;
    position: relative;
    bottom: 0;
    justify-content: center;
    color: #fff;
}

.footer-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.footer-item p {
    font-size: 13px;
    text-align: center;
}

