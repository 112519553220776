.hidden {
    display: none;
}

.registerShow {
    display: block;
    position: absolute;
    z-index: 25;
    right: 1%;
    top: 100%;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 1rem;
    background-color: #f5f4f4;
    color: #1c1c1c;
    box-shadow: 0 0 .6rem #1c1c1c;
    z-index: 90;
}

.signup-form h3 {
    margin-bottom: 1rem;
}

.signup-form .form-group {
    display: flex;
    margin-bottom: 10px;
    color: rgb(63, 61, 61);
    align-items: center;
    justify-content: center;
}

.signup-form input {
    width: 325px;
    padding: 3px 6px;
    height: 30px;
    font-size: 14px;
    border: solid 1px rgba(102, 100, 100, 0.349);
}

.signup-form button {
    margin: .25rem;
    padding: .45rem;
    border: .1rem solid #1c1c1c;
    border-radius: 1rem;
    font-size: .9em;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.signup-form button:hover{
    background-color: #1c1c1c;
    color: #f5f4f4;
    transition: ease-in-out .35s;
}

.signup-form .signup-form-save {
    background-color: #1c1c1c;
    color: #f5f4f4;
}

.signup-form .signup-form-save:hover {
    color: cyan;
    border: solid .1rem #1c1c1c;
}


.signup-form .invalid {
    position: relative;
    align-self: flex-start;
    font-size: .7rem;
    color: rgb(255, 30, 0);
    width: 60%;
    text-align: right;
}

.signup-form .form-group .is-invalid {
    border: .12rem solid rgb(255, 30, 0);
}

.signup-form small {
    margin-bottom: 1.5rem;
    text-decoration: underline;
    cursor: pointer;
}