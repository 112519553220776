.notFounded-div { 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(12, 12, 63, 0.788);
}

.notFounded-div div {
    width: 50%;
}

.notFounded-div h1 {
    font-size: 5rem;
}

.notFounded-div h3 {
    font-size: 2rem;
}

.notFounded-div p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
}

.notFounded-div svg {
    font-size: 8rem;
    position: absolute;
    right: 2.5rem;
    color: cyan;
    background-color: #8b8b8d5b;
    border-radius: 50%;
}

.notFounded-div a {
    text-decoration: none;
    color: cyan;
    cursor: pointer;
}

.notFounded-div a:hover {
    color: rgb(87, 174, 245);
    transition: ease 1s;
}

.notFounded-div .notFounded-text {
    position: relative;
    padding: 1rem;
    display: flex;
    width: 50%;
    flex-direction: column;
}