@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(-85%)}
}

#section-clientes {
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    
    background-color: #dde0e2;
    background: url('../../assets//wp4.jpg') no-repeat;
    background-size: cover;
	position: relative;
	
}

.section-clientes-glass {
    background-color: rgba(7, 30, 94, 0.719);
    color: #f5f4f4;
    display: flex;
    flex-direction: column;
    padding: 2rem;

}

.section-clientes-glass h3 {
    font-size: 1.5rem;
    width: fit-content;
    display: flex;
    align-self: center;
}

.section-clientes-glass h1 {
    font-size: 3rem;
    width: fit-content;
    display: flex;
    align-self: center;
}

.clientes-body {
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    animation: scroll 10s linear infinite alternate;
    display: flex;
}

.cliente-picture {
    margin: 20px;
    height: 200px;
    width: 300px;
    background-color: #fff;
    float: left;
}

.cliente-picture img {
    height: 200px;
    width: 300px;
    object-fit: contain;
}


@media screen and (max-width:700px){
    .clientes-body {
        flex-direction: column;
    }

    .section-clientes-glass h3{
        font-size: 1.25rem;
    }

    .section-clientes-glass h1 {
        font-size: 2rem;
        text-align: center;
    }

    .section-clientes-glass {
        padding: 15px;
    }

    .section-clientes-glass p {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }
    
    .cliente-picture {
        margin: 20px;
        height: 150px;
        width: 200px;
        background-color: #fff;
        float: left;
    }
        
    .cliente-picture img {
        height: 150px;
        width: 200px;
        object-fit: contain;
    }
}
