.header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    height: 4rem;
    background-color: rgba(43, 43, 43, 0);

    border-bottom: .125rem solid #8a8a8a3b;
}

.header .menu-social {
    left: 10%;
    position: absolute;
    width: 25%;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.header .menu-social a, .header .menu-social button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1rem;
    color: #f5f4f4;
    border: .1rem solid #f5f4f4;
    background-color: rgba(0, 0, 0, 0);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 2%;
    padding: 2.5%;
}

.header .menu-social svg {
    position: absolute;
}

.header .menu-social a:hover , .header .menu-social button:hover{
    transition: ease .6s;
    color: cyan;
    border-color: cyan;
}

.header .options-right {
    position: absolute;
    right: 20%;
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .options-right p {
    font-size: .9rem;
    display: flex;
    align-items: center;
    padding: 1%;
    margin-left: 5%;
}

.header .options-right svg {
    color: #f5f4f4;
    margin-right: .75rem;
    font-size: 1rem;
}

.header .options-login {
    position: absolute;
    right: 1rem;
    padding: 1rem;
    cursor: pointer;
}

.header .options-login button {
    color: #f5f4f4;
    text-decoration: none;
    background-color: #00000000;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    margin: .25rem;
    padding: 1rem;
}

.header .options-login button:hover {
    color: cyan;
    transition: ease-in-out .35s;
}

.header .options-login a svg, .header .options-login button svg {
    font-size: 1.5rem;
}

.options-login .dropdown-content {
    display: flex;
    z-index: 20;
    position: fixed;
    background-color: #f5f4f4;
    color: #1c1c1c;
    width: 12rem;
    min-width: 100px;
    box-shadow: .1rem 0 .9rem rgba(0, 0, 0, 0.5);
    top: 3.49rem;
    right: .5rem;
    flex-direction: column;
}

.options-login .dropdown-content p, .dropdown-content a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
    padding: 10px;
    text-align: left;
    display: flex;
    font-size: 13px;
    cursor: pointer;
}

.options-login .dropdown-content svg {
    right: 0;
    position: fixed;
}    

.options-login .dropdown-content p:hover, .options-login .dropdown-content a:hover {
    background-color: #99989c4d;
}

.options-login .hidden {
    display: none;
}

.nav-menu {
    position: relative;
    display: flex;
    align-items: center;
    height: 7.5rem;
    width: 100%;
    font-family: 'Roboto';
}

.nav-menu .nav-logo {
    position: absolute;
    left: 10%;
    display: flex;
    z-index: 10;
}

.nav-menu .nav-logo img {
    width: 110px;
    filter: drop-shadow(2px 2px 1px #000000);
}

.nav-menu ul {
    position: absolute;
    right: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    z-index: 9;
}

.nav-menu ul a {
    display: flex;
    height: 2.5rem;
    padding: 1rem;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    border-bottom: .25rem solid #1c1c1c00;
    text-decoration: none;
    color: #f5f4f4;
}

.nav-menu ul a:hover, .nav-menu .active {
    opacity: 1;
    cursor: pointer;
    border-bottom: .25rem solid cyan;
    transition: ease-in-out .5s;
}

@media screen and (max-width:700px) {
    .header {
        position: relative;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
        width: 100%;
        background-color: rgba(43, 43, 43, 0);
    
        border-bottom: .125rem solid #8a8a8a3b;
    }

    .header .menu-social {
        margin-top: 1rem;
        position: static;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
    }
        
    .header .options-right {
        width: 100%;
        position: static;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }   

    .header .options-right p {
        font-size: .8rem;
        margin-left: 0;
    }

    .header .options-right svg {
        margin-left: 0;
        margin-right: .25rem;
    }
    
    .header .options-login {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        position: static;
        padding: 1rem;
    }   
        
    .nav-menu {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 7.5rem;
        width: 100%;
        font-family: 'Roboto';
        margin-top: 1rem;
    }
        
    .nav-menu .nav-logo {
        position: static;
        display: flex;
        z-index: 10;
    }

    .nav-menu .nav-logo img {
        width: 125px;
        filter: drop-shadow(2px 2px 1px #000000);
    }
        
    .nav-menu ul {
        margin-top: 1rem;
        position: static;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        z-index: 9;
    }

    .options-login .dropdown-content {
        width: 15rem;
        min-width: 100px;
        box-shadow: .1rem 0 .9rem rgba(0, 0, 0, 0.5);
        top: 8rem;
        right: 3.5rem;
        flex-direction: column;
        position: absolute;
    }
}