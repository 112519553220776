.activate-section {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - (4rem + 7.5rem + 2rem));
    border-radius: 1.5rem;
    width: 500px;
    background-color: #f5f4f4;
    color: #1c1c1c;
}


.logo-area {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #f5f4f4;
    margin-bottom: 3rem;
}

.activate-section .error, .password-reset-section .error {
    background-color: rgb(163, 4, 4);
}

.activate-section .success, .password-reset-section .success {
    background-color: rgb(1, 82, 1);
}

.activate-section h3 {
    margin-bottom: 1rem;
}

.logo-area img {
    height: 100px;
}

.logo-area svg {
    font-size: 100px;
}