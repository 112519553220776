.section-contato {
    padding: 1rem;
    width: 100%;
    background-color: #f5f4f4;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: #1c1c1c;
}

.contato-item {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 1%;
    padding-top: 1rem;
    text-align: center;
}

.contato-item h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
}

.contato-item-content {
    flex-direction: column;
    width: 100%;
    padding-top: 5%;
    display: flex;
}

.contato-item-parceiros {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 1%;
    padding-top: 1rem;
    text-align: center;
}

.contato-item-content p {
    font-size: .8rem;
    align-items: center;
    align-content: center;
    display: flex;
}

.contato-item-label-group {
    height: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
}

.contato-item-label-group-parceiros {
    width: 100%;
    display: flex;
    padding: 2rem;
    justify-content: space-around;
}

.contato-item-label-group-parceiros img {
    height: 7.5rem;
}

.contato-item-label-group p{
    height: 100%;
    font-size: .95rem;
    display: flex;
    align-content: center;
    justify-content: center;
}

.contato-item-label-group label {
    height: 100%;
    font-size: 1.3rem;
    margin-left: 3%;
    margin-right: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contato-endereco {
    width: 40%;
    height: 100%;
    font-size: .9rem;
}

.contato-endereco iframe {
    width: 100%;
    height: 65%;
    margin-bottom: 3%;
    border: none;
}

.contato-endereco .contato-item-label-group {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
}

.contato-item-legal {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    height: 100%;
    width: 20%;
}


.contato-item-legal h3 {
    margin-left: 1rem;
    font-size: 1.3rem;
    margin-bottom: 5%;
}

@media screen and (max-width:700px) {
    .section-contato {
        flex-direction: column;
        padding: 25px;
    }

    .contato-item {
        width: 100%;
    }

    .contato-item-label-group-parceiros{
        flex-direction: column;
    }

    .contato-item-label-group-parceiros img {
        height: 125px;
        margin-bottom: 2rem;
    }
}
